/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var FN = {
    // All pages
    'common': {
      init: function () {

        var scrollTimeout;
        var throttle = 100;

        if (location.hash !== '') {
          var el = document.querySelector(window.location.hash);
          // if non-null (an other usage of the location.hash) and that it is at top of our viewport
          if (el && window.innerWidth >= 890) {
            document.querySelector('header').classList.add('sticky');
          }
        }

        $(window).on('scroll', function () {
          if (!scrollTimeout) {
            scrollTimeout = setTimeout(function () {
              // Detect scroll position
              let scrollPosition = Math.round(window.scrollY);

              // If we've scrolled 100px, add "sticky" class to the header
              if (scrollPosition > 0 && window.innerWidth >= 890) {
                document.querySelector('header').classList.add('sticky');
              }
              // If not, remove "sticky" class from header
              else {
                document.querySelector('header').classList.remove('sticky');
              }
              scrollTimeout = null;
            }, throttle);
          }
        });

        $('body').on('click', '.woof_mobile_filter_button', function (e) {
          e.preventDefault();
          $('.widget.WOOF_Widget').fadeIn();
        });


        $('body').on('click', '#popup_permanent-low-price .close', function (e) {
          e.preventDefault();
          $('#popup_permanent-low-price').fadeOut();
          createCookie('popup_permanent-low-price_closed', '1', 0);
        });

        $('body').on('click', '.woof_mobile_order_button', function (e) {
          e.preventDefault();
          if ($('.woocommerce-ordering .select2.select2-container').hasClass('select2-container--open')) {
            $('.woocommerce-ordering select').select2('close');
          } else {
            $('.woocommerce-ordering select').select2('open');
          }
        });

        $('body').on('click', '.woof_show_product,.woof_close_form', function (e) {
          e.preventDefault();
          $('.widget.WOOF_Widget').fadeOut();
        });

        $('body').on('change', '.cart .quantity input', function () {
          let val = $(this).val();
          $(this).closest('.cart').find('.add_to_cart_button').attr('data-quantity', val);
        });
        // $('body').on('mouseover', '#mega-menu-wrap-primary #mega-menu-primary  ul.mega-sub-menu .mega-menu-item', function () {
        //   if ($(this).hasClass('mega-menu-item-has-children')) {
        //     $('li.mega-menu-columns-4-of-12:first-of-type').css('visibility', 'visible');
        //   }
        //   else{
        //     $('li.mega-menu-item.mega-toggle-on').removeClass('mega-toggle-on');
        //     $('li.mega-menu-columns-4-of-12:first-of-type').css('visibility', 'hidden');
        //   }
        // });

        /*$('body').on('click', '.woof_front_toggle', function () {
          if (jQuery(this).data('condition') === 'opened') {
            jQuery(this).removeClass('woof_front_toggle_opened');
            jQuery(this).addClass('woof_front_toggle_closed');
            jQuery(this).data('condition', 'closed');
            if (woof_toggle_type === 'text') {
              jQuery(this).text(woof_toggle_closed_text);
            } else {
              jQuery(this).find('img').prop('src', woof_toggle_closed_image);
            }
          } else {
            jQuery(this).addClass('woof_front_toggle_opened');
            jQuery(this).removeClass('woof_front_toggle_closed');
            jQuery(this).data('condition', 'opened');
            if (woof_toggle_type === 'text') {
              jQuery(this).text(woof_toggle_opened_text);
            } else {
              jQuery(this).find('img').prop('src', woof_toggle_opened_image);
            }
          }


          jQuery(this).parents('.woof_container_inner').find('.woof_block_html_items').toggle(500);
          return false;
        });*/

        $('.footer-widgets .gamma.widget-title').on('click', function (e) {
          if ($(window).width() < 480) {
            $(this).toggleClass('opened');
            $(this).closest('.widget').find('ul').slideToggle();
          }
        });

        $('body').on('click', '.handheld-navigation ul.menu>li>.dropdown-toggle', function () {
          if ($(this).hasClass('toggled-on')) {
            $(this).closest('.menu-item').addClass('opened');
            $('.handheld-navigation ul.menu>li:not(.opened)').slideUp();
          } else {
            $(this).closest('.menu-item').removeClass('opened');
            $('.handheld-navigation ul.menu>li').slideDown();
          }
        });

        $('#site-navigation .menu-toggle').on('click', function () {
          $('#content,.site-footer').toggle();
          if ($('#masthead').css('position') == 'sticky') {
            $('#masthead').css('position', "relative");
          }
          else if ($('#masthead').css('position') == 'relative') {
            $('#masthead').css('position', "sticky");
          }
        });

        $(document).on("woof_ajax_done", function (e) {
          jQuery('.woof_meta_checkbox_container').last().addClass('last');
          $(".woof_front_toggle_opened").parent('.woof_container_inner').find('.woof_block_html_items').show();
          $(".woocommerce-ordering select").select2({
            minimumResultsForSearch: -1,
            dropdownAutoWidth: true,
            width: 'auto'
          });
        });

        if ($(".woocommerce-ordering select").length) {
          $(".woocommerce-ordering select").select2({            
            minimumResultsForSearch: -1,
            dropdownAutoWidth: true,
            width: 'auto'
          });
        }
        // $(".woocommerce-shipping-fields select#ShipmentPoint").select2({
        //   containerCssClass: 'ShipmentPoint',
        //   dropdownAutoWidth: true,
        //   width: '100%'
        // });
        // $('#ShIrsz').on('select2:select', function (e) {
        //   $('.select2-container .select2-selection.ShipmentPoint').css("display", "block");
        // });
        $('#ShIrsz').on('select2:select', function (e) {
          $('#ShipmentPoint').css("display", "block");
        });


        /* Swiper starts */
        let arr_next = $('.fn-next');
        let arr_prev = $('.fn-prev');

        $('.swiper-container--fn').each(function (index, element) {

          $(this).addClass('swiper' + index);
          arr_next[index].classList.add('fn-next' + index);
          arr_prev[index].classList.add('fn-prev' + index);


          const swiper = new Swiper('.swiper' + index, {
            navigation: {
              nextEl: '.fn-next' + index,
              prevEl: '.fn-prev' + index,
            },
            slidesPerView: 1,
            spaceBetween: 30,
            loop_: true,
            a11y: false,
            uniqueNavElements: false,
            noSwipingSelector: 'input',
            breakpoints: {
              961: {
                slidesPerView: 5,
                spaceBetween: 35,
              },
              650: {
                slidesPerView: 3,
                spaceBetween: 35,
              },
            },
          });

          swiper.loopDestroy();
          // swiper.loopCreate();

        });

        let arr_next_brand = jQuery('.fn-next_brand');
        let arr_prev_brand = jQuery('.fn-prev_brand');

        jQuery('.swiper-container--fn-brand').each(function (index, element) {

          jQuery(this).addClass('swiper_brand' + index);
          arr_next_brand[index].classList.add('fn-next_brand' + index);
          arr_prev_brand[index].classList.add('fn-prev_brand' + index);

          new Swiper('.swiper_brand' + index, {
            navigation: {
              nextEl: '.fn-next_brand' + index,
              prevEl: '.fn-prev_brand' + index,
            },
            slidesPerView: 2,
            spaceBetween: 30,
            loop: true,
            a11y: false,
            uniqueNavElements: false,
            noSwipingSelector: 'input',
            breakpoints: {
              961: {
                slidesPerView: 8,
                spaceBetween: 35,
              },
              650: {
                slidesPerView: 4,
                spaceBetween: 35,
              },
            },
          });
        });

        /* Swiper ends */

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
      },
      finalize: function () {

      },
    },
    // Single product page
    'single_product': {
      init: function () {

        var previousCss = $(".the_content").attr("style");

        $(".the_content").css({
          position: 'absolute', // Optional if #myDiv is already absolute
          visibility: 'hidden',
          display: 'block'
        });

        var optionHeight = $(".the_content").height();

        $(".the_content").attr("style", previousCss ? previousCss : "");

        if (optionHeight >= 240) {
          $('.read_more').css('visibility', 'visible');
          $(document).on('click', '.btn_read-more', function (e) {
            e.preventDefault();
            $(this).parent().fadeOut();
            $('.the_content').css('max-height', '100%');
          });
        }

        $('.entry-summary .woocommerce-review-link').on('click', function (e) {
          e.preventDefault();
          window.location.hash = $(this).attr("href");
          $([document.documentElement, document.body]).animate({
            scrollTop: $(".tabs.wc-tabs").first().offset().top - 100
          }, 500);

        });

        // $(document).on('change', '.wc-tabs-mobile', function () {
        //   $('ul.tabs.wc-tabs li.active').removeClass('active');
        //   console.log(this.value.replace('#',''));
        //   $( 'ul.tabs.wc-tabs li[aria-controls="'+ this.value.replace('#','')  +'"]' ).addClass( 'active' );
        //   $('.woocommerce-Tabs-panel').hide();
        //   $(this.value).show();
        // });

      },
      finalize: function () {

      },
    },
    'woocommerce_cart': {
      init: function () {

        // Quantity "plus" and "minus" buttons
        $(document.body).on('click', '.plus, .minus', function () {

          var $qty = $(this).closest('.product-quantity').find('.qty'),
            currentVal = parseFloat($qty.val()),
            max = parseFloat($qty.attr('max')),
            min = parseFloat($qty.attr('min')),
            step = $qty.attr('step');

          // Format values
          if (!currentVal || currentVal === '' || currentVal === 'NaN') {
            currentVal = 0;
          }
          if (max === '' || max === 'NaN') {
            max = '';
          }
          if (min === '' || min === 'NaN') {
            min = 0;
          }
          if (step === 'any' || step === '' || step === undefined || parseFloat(step) === 'NaN') {
            step = 1;
          }

          // Change the value
          if ($(this).is('.plus')) {
            if (max && (currentVal >= max)) {
              $qty.val(max);
            } else {
              $qty.val((currentVal + parseFloat(step)).toFixed(step.getDecimals()));
            }
          } else {
            if (min && (currentVal <= min)) {
              $qty.val(min);
            } else if (currentVal > 0) {
              $qty.val((currentVal - parseFloat(step)).toFixed(step.getDecimals()));
            }
          }
          // Trigger change event
          $qty.trigger('change');
        });

      }
    },
    'woocommerce_edit_address': {
      init: function () {


        if ($('#billing_phone').length) {
          $('#billing_phone').mask('+36000000000');
        }

        if (typeof surbma_hc_postcodes !== 'undefined') {
          if ($('#billing_postcode').length) {


            $('#billing_postcode').mask('0000');
            var $postcodeFieldBilling = $('#billing_postcode');
            var $cityFieldBilling = $('#billing_city');


            $postcodeFieldBilling.on('input change keyup', function () {

              var postcodeBilling = parseInt($postcodeFieldBilling.val());
              var cityIndexBilling = surbma_hc_postcodes.indexOf(postcodeBilling);
              var cityBilling = surbma_hc_cities[cityIndexBilling];


              if ($postcodeFieldBilling.val().length === 4) {

                if (cityIndexBilling > -1) {
                  if (surbma_hc_postcodes[cityIndexBilling + 1] !== postcodeBilling) {
                    $($cityFieldBilling).empty().append('<option value="' + cityBilling + '">' + cityBilling + '</option>').addClass("disabled");
                  } else {
                    $($cityFieldBilling).empty().removeClass("disabled");
                    while (surbma_hc_postcodes[cityIndexBilling] === postcodeBilling) {
                      $($cityFieldBilling).append('<option value="' + cityBilling + '">' + cityBilling + '</option>');
                      cityIndexBilling++;
                      cityBilling = surbma_hc_cities[cityIndexBilling];
                    }
                  }

                  if ($cityFieldBilling.val() !== '' && $("#billing_city_field").closest('.form-row').hasClass("woocommerce-invalid woocommerce-invalid-required-field")) {
                    $("#billing_city_field").closest('.form-row').removeClass("woocommerce-invalid woocommerce-invalid-required-field");
                  }
                  if ($cityFieldBilling.val() !== '') {
                    $("#billing_city_field").closest('.form-row').addClass("woocommerce-validated");
                  }
                  $('body').trigger('update_checkout');
                }
                else {
                  $("#billing_postcode").closest('.form-row').removeClass("woocommerce-validated");
                  $("#billing_postcode").closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $('#billing_city').closest('.form-row').removeClass("woocommerce-validated");
                  $('#billing_city').closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $($cityFieldBilling).empty();
                }
              }
            });
          }

          if ($('#shipping_postcode').length) {
            $('#shipping_postcode').mask('0000');
            var $postcodeFieldShipping = $('#shipping_postcode');
            var $cityFieldShipping = $('#shipping_city');

            $postcodeFieldShipping.on('input change keyup', function () {

              var postcodeShipping = parseInt($postcodeFieldShipping.val());
              var cityIndexShipping = surbma_hc_postcodes.indexOf(postcodeShipping);
              var cityShipping = surbma_hc_cities[cityIndexShipping];


              if ($postcodeFieldShipping.val().length === 4) {

                if (cityIndexShipping > -1) {
                  if (surbma_hc_postcodes[cityIndexShipping + 1] !== postcodeShipping) {
                    $($cityFieldShipping).empty().append('<option value="' + cityShipping + '">' + cityShipping + '</option>').addClass("disabled");
                  } else {
                    $($cityFieldShipping).empty().removeClass("disabled");
                    while (surbma_hc_postcodes[cityIndexShipping] === postcodeShipping) {
                      $($cityFieldShipping).append('<option value="' + cityShipping + '">' + cityShipping + '</option>');
                      cityIndexShipping++;
                      cityShipping = surbma_hc_cities[cityIndexShipping];
                    }
                  }

                  if ($cityFieldShipping.val() !== '' && $("#Shipping_city_field").closest('.form-row').hasClass("woocommerce-invalid woocommerce-invalid-required-field")) {
                    $("#shipping_city_field").closest('.form-row').removeClass("woocommerce-invalid woocommerce-invalid-required-field");
                  }
                  if ($cityFieldShipping.val() !== '') {
                    $("#shipping_city_field").closest('.form-row').addClass("woocommerce-validated");
                  }
                  $('body').trigger('update_checkout');
                }
                else {
                  $("#shipping_postcode").closest('.form-row').removeClass("woocommerce-validated");
                  $("#shipping_postcode").closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $('#shipping_city').closest('.form-row').removeClass("woocommerce-validated");
                  $('#shipping_city').closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $($cityFieldShipping).empty();
                }
              }
            });
          }
        }
      }
    },
    'woocommerce_checkout': {
      init: function () {

        $('body').on('blur change', '#billing_phone', function () {
          let prefix = ['20', '21', '30', '31', '50', '70'];
          const wrapper = $(this).closest('.form-row');
          let val = $(this).val();

          if ($(this).val().length !== 12) {
            wrapper.addClass('woocommerce-invalid'); // error
            return;
          }
          if (!prefix.includes(val.substring(3, 5))) {
            wrapper.addClass('woocommerce-invalid'); // error 
            return;
          }
          wrapper.addClass('woocommerce-validated'); // success

        });

        if ($('#billing_phone').length) {
          $('#billing_phone').mask('+36000000000');
        }

        if (typeof surbma_hc_postcodes !== 'undefined') {
          if ($('#billing_postcode').length) {


            $('#billing_postcode').mask('0000');
            var $postcodeFieldBilling = $('#billing_postcode');
            var $cityFieldBilling = $('#billing_city');


            $postcodeFieldBilling.on('input change keyup', function () {

              var postcodeBilling = parseInt($postcodeFieldBilling.val());
              var cityIndexBilling = surbma_hc_postcodes.indexOf(postcodeBilling);
              var cityBilling = surbma_hc_cities[cityIndexBilling];


              if ($postcodeFieldBilling.val().length === 4) {

                if (cityIndexBilling > -1) {
                  if (surbma_hc_postcodes[cityIndexBilling + 1] !== postcodeBilling) {
                    $($cityFieldBilling).empty().append('<option value="' + cityBilling + '">' + cityBilling + '</option>').addClass("disabled");
                  } else {
                    $($cityFieldBilling).empty().removeClass("disabled");
                    while (surbma_hc_postcodes[cityIndexBilling] === postcodeBilling) {
                      $($cityFieldBilling).append('<option value="' + cityBilling + '">' + cityBilling + '</option>');
                      cityIndexBilling++;
                      cityBilling = surbma_hc_cities[cityIndexBilling];
                    }
                  }

                  if ($cityFieldBilling.val() !== '' && $("#billing_city_field").closest('.form-row').hasClass("woocommerce-invalid woocommerce-invalid-required-field")) {
                    $("#billing_city_field").closest('.form-row').removeClass("woocommerce-invalid woocommerce-invalid-required-field");
                  }
                  if ($cityFieldBilling.val() !== '') {
                    $("#billing_city_field").closest('.form-row').addClass("woocommerce-validated");
                  }
                  $('body').trigger('update_checkout');
                }
                else {
                  $("#billing_postcode").closest('.form-row').removeClass("woocommerce-validated");
                  $("#billing_postcode").closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $('#billing_city').closest('.form-row').removeClass("woocommerce-validated");
                  $('#billing_city').closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $($cityFieldBilling).empty();
                }
              }
            });
          }

          if ($('#shipping_postcode').length) {
            $('#shipping_postcode').mask('0000');
            var $postcodeFieldShipping = $('#shipping_postcode');
            var $cityFieldShipping = $('#shipping_city');

            $postcodeFieldShipping.on('input change keyup', function () {

              var postcodeShipping = parseInt($postcodeFieldShipping.val());
              var cityIndexShipping = surbma_hc_postcodes.indexOf(postcodeShipping);
              var cityShipping = surbma_hc_cities[cityIndexShipping];


              if ($postcodeFieldShipping.val().length === 4) {

                if (cityIndexShipping > -1) {
                  if (surbma_hc_postcodes[cityIndexShipping + 1] !== postcodeShipping) {
                    $($cityFieldShipping).empty().append('<option value="' + cityShipping + '">' + cityShipping + '</option>').addClass("disabled");
                  } else {
                    $($cityFieldShipping).empty().removeClass("disabled");
                    while (surbma_hc_postcodes[cityIndexShipping] === postcodeShipping) {
                      $($cityFieldShipping).append('<option value="' + cityShipping + '">' + cityShipping + '</option>');
                      cityIndexShipping++;
                      cityShipping = surbma_hc_cities[cityIndexShipping];
                    }
                  }

                  if ($cityFieldShipping.val() !== '' && $("#Shipping_city_field").closest('.form-row').hasClass("woocommerce-invalid woocommerce-invalid-required-field")) {
                    $("#shipping_city_field").closest('.form-row').removeClass("woocommerce-invalid woocommerce-invalid-required-field");
                  }
                  if ($cityFieldShipping.val() !== '') {
                    $("#shipping_city_field").closest('.form-row').addClass("woocommerce-validated");
                  }
                  $('body').trigger('update_checkout');
                }
                else {
                  $("#shipping_postcode").closest('.form-row').removeClass("woocommerce-validated");
                  $("#shipping_postcode").closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $('#shipping_city').closest('.form-row').removeClass("woocommerce-validated");
                  $('#shipping_city').closest('.form-row').addClass("woocommerce-invalid woocommerce-invalid-required-field");
                  $($cityFieldShipping).empty();
                }
              }
            });
          }
        }
      }
    },
    'archive': {
      init: function () {
        $(document).on('click', 'span.read-more', function (e) {
          if ($(this).hasClass('read-more__open')) {
            $(this).hide();
            $('.term-description__more').slideDown();
          } else {
            $('.read-more__open').fadeIn();
            $('.term-description__more').slideUp();
          }
        });
        jQuery('.woof_meta_checkbox_container').last().addClass('last');
      }
    },
    'page_template_template_brand_landing_page': {
      init: function () {
        $(document).on('click', '.species_selector a.btn:not(.active)', function (e) {
          e.preventDefault();
          $('.species_selector a.btn.active').removeClass('active');
          $(this).addClass('active');
          target = $(this).attr('href');
          $('.species-container.active').removeClass('active');
          $(target).addClass('active');
          $([document.documentElement, document.body]).animate({
            scrollTop: $(target).first().offset().top - 100
          }, 500);
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = FN;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {

      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function createCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  var x = readCookie('cookie_settings');
  if (!x) {
    $(".grey-popup-layer, .cookie-base").show();
  }

  $(".accept-default").click(function (event) {
    event.preventDefault();
    $('.grey-popup-layer').hide();
    $('#popup_permanent-low-price').trigger('cookieSettingsHideEvent');
    createCookie('cookie_settings', '3', 365);

    window.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted'
    });

  });

  $(".accept-user-settings").click(function (event) {
    event.preventDefault();
    $('.grey-popup-layer').hide();
    $('#popup_permanent-low-price').trigger('cookieSettingsHideEvent');

    var level = parseInt($('#myRange').val());
    var consent = {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted'
    };

    switch (level) {
      case 1:
        consent.ad_user_data = 'denied';
        consent.ad_personalization = 'denied';
        consent.ad_storage = 'denied';
        consent.analytics_storage = 'denied'

        break;
      case 2:
        consent.ad_storage = 'denied';
        consent.ad_user_data = 'denied';
        consent.ad_personalization = 'denied';
        break;
      case 3:
        break;
    }
    window.gtag('consent', 'update', consent);

    createCookie('cookie_settings', $('#myRange').val(), 365);
  });

  $(".show-settings").click(function (event) {
    event.preventDefault();
    $('.cookie-base').hide();
    $('.cookie-details').fadeIn();
  });

  if (!String.prototype.getDecimals) {
    String.prototype.getDecimals = function () {
      var num = this,
        match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
      if (!match) {
        return 0;
      }
      return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
    };
  }

  $(document).on('input change', '#myRange', function () {

    if (parseInt($(this).val()) === 1) {

      $('.grey-box-part-title').html(cookie_level_1_cim);
      $('.grey-box-c').html(cookie_level_1_tartalom);
      $('.cookie-type-1 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-2 .cookie-type-title').css('color', '#ccc');
      $('.cookie-type-3 .cookie-type-title').css('color', '#ccc');
      if (/WebKit/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)) {
        $('.slider').css('background', '#eee');
      }
    }
    if (parseInt($(this).val()) === 2) {
      $('.grey-box-part-title').html(cookie_level_2_cim);
      $('.grey-box-c').html(cookie_level_2_tartalom);
      $('.cookie-type-3 .cookie-type-title').css('color', '#ccc');
      $('.cookie-type-1 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-2 .cookie-type-title').css('color', 'rgb(55,55,55)');
      if (/WebKit/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)) {
        $('.slider').css('background', 'linear-gradient(to right, rgb(231, 0, 31), rgb(238, 238, 238) 60%, rgb(238, 238, 238))');
      }
    }
    if (parseInt($(this).val()) === 3) {
      $('.grey-box-part-title').html(cookie_level_3_cim);
      $('.grey-box-c').html(cookie_level_3_tartalom);
      $('.cookie-type-1 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-2 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-3 .cookie-type-title').css('color', 'rgb(55,55,55)');
      if (/WebKit/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)) {
        $('.slider').css('background', 'rgb(231, 0, 31)');
      }
    }
  });

  $('.cookie-type').on('click touchend', function () {
    $('.slider').val($(this).index());

    if (parseInt($('.slider').val()) === 1) {
      $('.grey-box-part-title').html(cookie_level_1_cim);
      $('.grey-box-c').html(cookie_level_1_tartalom);
      $('.cookie-type-1 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-2 .cookie-type-title').css('color', '#ccc');
      $('.cookie-type-3 .cookie-type-title').css('color', '#ccc');
      if (/WebKit/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)) {
        $('.slider').css('background', '#eee');
      }
    }
    if (parseInt($('.slider').val()) === 2) {
      $('.grey-box-part-title').html(cookie_level_2_cim);
      $('.grey-box-c').html(cookie_level_2_tartalom);
      $('.cookie-type-3 .cookie-type-title').css('color', '#ccc');
      $('.cookie-type-1 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-2 .cookie-type-title').css('color', 'rgb(55,55,55)');
      if (/WebKit/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)) {
        $('.slider').css('background', 'linear-gradient(to right, rgb(231, 0, 31), rgb(238, 238, 238) 60%, rgb(238, 238, 238))');
      }
    }
    if (parseInt($('.slider').val()) === 3) {
      $('.grey-box-part-title').html(cookie_level_3_cim);
      $('.grey-box-c').html(cookie_level_3_tartalom);
      $('.cookie-type-1 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-2 .cookie-type-title').css('color', 'rgb(55,55,55)');
      $('.cookie-type-3 .cookie-type-title').css('color', 'rgb(55,55,55)');
      if (/WebKit/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)) {
        $('.slider').css('background', 'rgb(231, 0, 31)');
      }
    }
  });

  $('.slider').on('touchstart', function () {
    $('.grey-popup-layer').addClass('fixed');
  }).on('touchend', function () {
    $('.grey-popup-layer').removeClass('fixed');
  });

  $(document).ready(function () {
    var topbar_closed = readCookie('topbar_closed');
    var modal_closed = readCookie('modal_closed');
    var popup_permanent_low_price_closed = readCookie('popup_permanent-low-price_closed');
    var cookie_settings = readCookie('cookie_settings');

    var urlParams = new URLSearchParams(window.location.search); //get all parameters
    var checkbox_is_permanently_low_price = urlParams.get('is_permanently_low_price'); //extract the foo parameter - this will return NULL if foo isn't a parameter

    if (cookie_settings && !popup_permanent_low_price_closed && !checkbox_is_permanently_low_price) {
      $('#popup_permanent-low-price').show();
    }
    if (!topbar_closed) {
      $('#topbar').show();
    }
    $('#popup_permanent-low-price').on('cookieSettingsHideEvent', function (cookie_settings,popup_permanent_low_price_closed,checkbox_is_permanently_low_price) {
    console.log(cookie_settings);
      if (cookie_settings && !popup_permanent_low_price_closed && !checkbox_is_permanently_low_price) {       
          $('#popup_permanent-low-price').fadeIn();       
      }
    });

    if (!modal_closed && topbar_closed && window.location.href.indexOf("utm_source=edm") === -1) {
      $('#modalSingupPopup').modal({
        backdrop: false
      });
    }

    $('#topbar .close').on('click', function () {
      $('#topbar').slideUp();
      createCookie('topbar_closed', '1', 7);
    });

    $('#modalSingupPopup').on('hidden.bs.modal', function () {
      if (!readCookie('modal_closed')) {
        createCookie('modal_closed', '1', 7);
      }
    });

    $('body').on('click', '#modalSingupPopup .btn-primary', function () {
      $('#modalSingupPopup').modal('hide');
      createCookie('modal_closed', '1', 7);
      window.open('https://fressnapf.hu/regisztracio');
    });
    $('body').on('click', '#modalSingupPopup .modal-footer a.closeModal', function () {
      createCookie('modal_closed', '1', 365);
      $('#modalSingupPopup').modal('hide');
    });

    if (jQuery("input[name='quantity']").length) {
      jQuery('input[name="quantity"]').on("click", function (e) {
        const value = this.value,
          max = this.getAttribute("max"),
          maxed = this.dataset.maxed === "true";

        jQuery(this).tooltip({
          title: "Legfeljebb " + max + " db áll rendelkezésre."
        });

        if (value === max && maxed) {
          jQuery(this).tooltip('enable');
          jQuery(this).tooltip('show');
        } else {
          jQuery(this).tooltip('hide');
          jQuery(this).tooltip('disable');
        }

        this.dataset.maxed = value === max ? "true" : "false";
      });
    }

  });

})(jQuery); // Fully reference jQuery after this point.
